import React from 'react';
import PageTitle from '../../utils/PageTitle';

const ContactPage: React.FC = () => {
  return (
    <div className="bg-gray-100 h-screen py-12 px-4 sm:px-6 lg:px-8">
      <PageTitle title='Contact' />
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="mt-8 text-3xl font-extrabold text-gray-900 sm:text-4xl">Contact New Zyner</h2>
        </div>
        <div className="mt-12">
          <p className="text-xl text-gray-700 leading-relaxed">
            We'd love to hear from you! Feel free to reach out to us via email or follow us on social media for the
            latest updates and news.
          </p>
          <div className="mt-8 text-xl text-gray-700 leading-relaxed">
            <p>Email: <a href="mailto:contact@newzyner.com" className="text-blue-600">contact@newzyner.com</a></p>
            <p>Instagram: <a href="https://www.instagram.com/newzyner" className="text-blue-600">@newzyner</a></p>
            <p>YouTube: <a href="https://www.youtube.com/c/newzyner" className="text-blue-600">newzyner</a></p>
            <p>Twitter: <a href="https://twitter.com/newzyner" className="text-blue-600">@newzyner</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
