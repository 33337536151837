import React from 'react';
import PageTitle from '../../utils/PageTitle';

const AboutPage: React.FC = () => {
  return (
    <div className="bg-gray-100  py-12 px-4 sm:px-6 lg:px-8">
      <PageTitle title='About New Zyner' />

      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="mt-8 text-3xl font-extrabold text-gray-900 sm:text-4xl">About The New Zyner</h2>
          <p className="mt-4 text-lg text-gray-600">
            Welcome to The New Zyner, where fashion meets innovation, and style knows no bounds.
          </p>
        </div>
        <div className="mt-12">
          <p className="text-xl text-gray-700 leading-relaxed">
            At The New Zyner, we believe that fashion is not just about clothing—it's about expression, creativity, and
            embracing the future. We're not just another fashion brand; we're a movement, a platform for individuals
            who dare to push the boundaries of style and step into the realm of the extraordinary.
          </p>
          <p className="mt-6 text-xl text-gray-700 leading-relaxed">
            Our curated collection is a fusion of modernity and futurism, showcasing the latest trends from both
            established brands and emerging designers. From sleek streetwear to avant-garde haute couture, our pieces
            are meticulously selected to inspire and empower our customers to express their unique identities through
            fashion.
          </p>
          <p className="mt-6 text-xl text-gray-700 leading-relaxed">
            What sets us apart is our commitment to inclusivity and diversity. Our models represent a spectrum of
            backgrounds, cultures, and identities, reflecting the rich tapestry of humanity. We believe that everyone
            deserves to see themselves represented in fashion, and we strive to create a space where all are welcomed
            and celebrated.
          </p>
          <p className="mt-6 text-xl text-gray-700 leading-relaxed">
            Beyond clothing, The New Zyner is a community—a gathering place for fashion enthusiasts, trendsetters, and
            visionaries. Through our blog, social media channels, and events, we foster dialogue, creativity, and
            collaboration, inspiring our followers to explore, experiment, and redefine what it means to be stylish in
            the 21st century.
          </p>
          <p className="mt-6 text-xl text-gray-700 leading-relaxed">
            Join us on this exhilarating journey as we continue to push the boundaries of fashion, challenge the
            status quo, and shape the future of style. Together, let's make a statement, make an impact, and make our
            mark on the world.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
