import React from 'react';

const HeroSection: React.FC = () => {
  return (
    <section className="hero flex justify-center items-center h-screen bg-gradient-to-r from-white to-gray-100">
      <div className="container mx-auto px-4 md:px-12 lg:px-24">
        <div className="flex flex-col md:flex-row md:space-x-12 lg:space-x-20">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl mb-2 tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Welcome to </span>
              </h1>
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">The </span>
                <span className="block text-indigo-400 xl:inline"> New Zyner</span>
              </h1>
              <p className="mt-3 text-lg text-gray-700 sm:mt-5 sm:text-xl sm:max-w-xl sm:mx-auto md:mt-5 md:text-2xl lg:mx-0">
              Your ultimate destination for discovering the forefront of fashion trends across all brands, whether big or small. At New Zyner, we invite you to express your unique style.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a href="/" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-500 md:py-4 md:text-lg md:px-10">
                    Explore Now
                  </a>
                </div>
              </div>
            </div>
          </main>
          <div className="mt-8 md:mt-0 flex justify-center items-center">
            <img src="https://i.pinimg.com/originals/15/f8/d9/15f8d95466dea0f7e077705fcc29b702.gif" alt="New Zyner Fashion" className="w-full rounded-lg shadow-lg max-w-sm md:max-w-md lg:max-w-2xl" />
            {/* Replace with your high-quality, unique hero image */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
