// components/Features.tsx
import React from 'react';

const Features: React.FC = () => {
  return (
    <section className="py-12 bg-gray-200">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Elevate Your Style with New Zyner</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Add feature cards here */}
        </div>
      </div>
    </section>
  );
}

export default Features;
