import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-gray-300 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h3 className="text-lg font-semibold mb-2">Follow Us</h3>
            <ul className="flex space-x-4">
              <li>
                <a href="https://www.instagram.com/newzyner" target="_blank" rel="noopener noreferrer" className="hover:text-white">
                  Instagram
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/c/newzyner" target="_blank" rel="noopener noreferrer" className="hover:text-white">
                  YouTube
                </a>
              </li>
              <li>
                <a href="https://twitter.com/newzyner" target="_blank" rel="noopener noreferrer" className="hover:text-white">
                  Twitter
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h3 className="text-lg font-semibold mb-2">Contact Us</h3>
            <p>Email: <a href="mailto:contact@newzyner.com" className="hover:text-white">contact@newzyner.com</a></p>
          </div>
          <div className="w-full md:w-1/3">
            <p className="text-center md:text-right">&copy; {new Date().getFullYear()} New Zyner. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
