// Home.tsx
import React from 'react';
import Testimonials from '../../components/Testimonials';
import HeroSection from '../../layouts/HeroSection';
import Features from '../../layouts/Features';

const HomePage: React.FC = () => {
  return (
    <div>
      <HeroSection />
      <Features />
      <Testimonials />
    </div>
  );
};

export default HomePage;
