import React from 'react';
import PageTitle from '../../utils/PageTitle';

const ProductsPage: React.FC = () => {
  return (
    <div className="bg-gray-100 h-screen py-12 px-4 sm:px-6 lg:px-8">
      <PageTitle title="New Zyner's fashion products" />
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="mt-8 text-3xl font-extrabold text-gray-900 sm:text-4xl">Products</h2>

        </div>
        <div className="mt-12">
        <div className="text-center">
        <p className="text-xl text-gray-700 leading-relaxed">
            Exciting products are on the way! Stay tuned for the latest additions of New Zyner's collection.
          </p>
        </div>
          <div className="mt-8 flex justify-center">
            <img src={'https://t3.ftcdn.net/jpg/03/80/80/62/360_F_380806214_SOArEWSg9uKnRiXvqzaSKbqFoKUwGePZ.jpg'} alt="Coming Soon New Zyner products fashion" className="max-w-xs sm:max-w-md md:max-w-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
