import React, { useState } from 'react';

const Header: React.FC = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-white shadow-md z-50">
      <div className="container mx-auto px-4 py-2 md:py-4 flex justify-between items-center">
        <div>
        <a href="/" className='text-2xl font-bold text-gray-800' >New Zyner</a>
          {/* <h1 className="text-2xl font-bold text-gray-800">New Zyner</h1> */}
        </div>
        <div className="md:hidden">
          <button
            className="focus:outline-none"
            onClick={toggleMobileMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMobileMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
        <nav className={`md:flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-8 ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
          <div className="text-gray-700 hover:text-indigo-400">
            <a href="/about" onClick={toggleMobileMenu}>About</a>
          </div>
          <div className="text-gray-700 hover:text-indigo-400">
            <a href="/products" onClick={toggleMobileMenu}>Products</a>
          </div>
          {/* Add more navigation links here as needed */}
          <div className="text-gray-700 hover:text-indigo-400">
            <a href="/contact" onClick={toggleMobileMenu}>Contact</a>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
